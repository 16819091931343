import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import capturingLead from "../../../assets/images/blog/idday-min.png"
import theVideo from "../../../assets/videos/IDDay_Video_2018_small.mp4"
import videoBg from "../../../assets/images/blog/video_bg-min.jpg"

export default () => 
<div>
    <SEO title={'Iron Deficiency Day 2018 | ICON Worldwide'} 
    description="Vifor Pharma led Iron Deficiency Day 2018 and raised awareness through a multi-channel program across 22 Countries: discover more!"
    canonical={'https://icon-worldwide.com/blog/iron-deficiency-day-2018'}/>

    <NewsHeader/>

    <div id="single-news">
        <h1>Vifor Pharma led <span>Iron Deficiency Day</span> 2018 Raises Awareness Via Multi-Channel Program Across 22 Countries</h1>
        <div id="title-underline"></div>
        <h2>Raising Awareness Around the World</h2>
        <video poster={videoBg} controls>
            <source src={theVideo} type="video/mp4"></source>
        </video>
        <div id="single-news-texts">
            <p>In it’s third year, Iron Deficiency Day continued to gain traction on an increasingly global scale. Extending the “Meet the Symptoms” theme, this years campaign was rolled out across 25 affiliate markets.</p>
            <p>The “Meet the Symptoms” campaign is designed to raise awareness of the common everyday symptoms which may be signs of iron deficiency. Playful, animated characters, raise awareness in a non-threatening manner.</p>
            <p>Website were launched in English, Spanish, Greek, Czech, Slovakian and Romanian, and many affiliates did local events to raise awareness.</p>
            <p>Social media plays a large role in reaching the global audience. In the period leading up to the campaign individual posts on Twitter, Facebook and Instagram engaged millions of users.</p>
            <h2>The Iron Deficiency Day campaign highlights ICON’s full-service promise. </h2>
            <p>The complete program was executed by our in-house team including: Corporate Identity, Logo, Website design and development in Durpal, original illustrations and animations, print production, SEO and social media promotion. We’re recognized as a top Logo Design Company on <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.designrush.com/agency/logo-design">DesignRush</a>.</p>
        </div>

        <img src={capturingLead} style={{width:'100%'}} alt="iron deficiency day 2018, ICON Worldwide Blog, logo" title="ICON Worldwide blog, iron deficiency day 2018, logo"/>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>
        <NewsFooter previous="how-gdpr-changed-seo-content-marketing" next="how-to-lead-ai-iot-blockchain-projects"/>
    </div>

    <Footer noScrollbar="true"/>
</div>